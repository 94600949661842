/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 coin.gltf 
Author: Jakob Henerey (https://sketchfab.com/hellonintendo8)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/coin-2d814553c1f64fbd9550d4f7af25dd32
Title: Coin
*/

import React, { useEffect, useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export default function Model(props) {
  const ref = useRef();
  const { nodes, materials } = useGLTF('/coin.gltf');
 // Вертикальное движение
 const [initialPositionY, setInitialPositionY] = useState(0);
 const [positionY, setPositionY] = useState(0);
 const [up,setUp]=useState(true)
 const [flag,setFlag]=useState(false)
 useEffect(() => {
   if (ref.current) {
     // Устанавливаем начальное положение монеты при первом рендере
     setInitialPositionY(ref.current.position.y);
     setPositionY(ref.current.position.y);
   }
 }, []);

 useFrame(() => {
  setFlag(false)
  //console.log(ref.current.rotation.y)
  //console.log(props.rotationSpeed)
  //console.log(ref.current.position.y)
  
   if (ref.current) {

    if(props.rotationSpeed==0.1){
      ref.current.rotation.y += props.rotationSpeed;
    }
     // Поворачиваем модель по заданной скорости
     if (positionY<1.37&&props.rotationSpeed>0.1&&up) {
       ref.current.rotation.y += props.rotationSpeed;
       setPositionY(positionY+0.02);
     }
     else if(positionY>0){
      setUp(false)      
        ref.current.rotation.y-=1
        setPositionY(positionY-0.03)
     }

     else if(positionY<0&&!flag){
      ref.current.rotation.y=0
      props.setRotationSpeed(0)
      setFlag(true)
      setUp(true)
     }

     
     ref.current.position.y = positionY;
     if(flag){      
      ref.current.position.y=0
    }
   }
 });



  return (
    <group ref={ref} {...props} dispose={null}>
      <mesh
        geometry={nodes.Object_2.geometry}
        material={materials.coin}
        rotation={[-Math.PI / 2, 0, 0]}
      />
    </group>
  );
}

useGLTF.preload('/coin.gltf');
