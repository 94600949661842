import luckyUP from '../images/lacky/logolucky.svg'
import '../styles/LackyJet_style.css'
import signal from '../images/lacky/buttonlucky.svg'
import pers from '../images/lacky/pers.gif'
import { useContext, useEffect, useState } from 'react';
import persfly from '../images/lacky/persfly.gif'
import { Link } from 'react-router-dom';
import { languages } from '../language';
import { Context } from '../context';
import '../styles/LackyJet_style.css'


function LackyJet() {
    const [counter, setCounter] = useState(100); // Состояние для хранения текущего значения счетчика
    const [startCounting, setStartCounting] = useState(false); // Состояние для отслеживания начала отсчета
    const [step, setStep] = useState(0);
    const [random,setRandom] = useState(0);
    const [showingAnimation, setShowingAnimation] = useState(false)
    const [showingPers, setShowingPers] = useState(true)
    const [currentTime, setCurrentTime] = useState(new Date())
    const [showingTime, setShowingTime] = useState(false)

    const [remainingTime, setRemainingTime] = useState(60); 

    
    const {lang,setlang} = useContext(Context)
    const data=languages[lang]

    const generateRandomNumber = () => {
        // Генерация случайного числа в диапазоне от 120 до 360
        const randomNumber = Math.floor(Math.random() * (360 - 120 + 1)) + 120;
        setRandom(randomNumber); // Устанавливаем новое случайное число

      };

      /*
      const timeToStart=()=>{
        const intervalId = setInterval(() => {
            setRemainingTime(prevTime => {
              const updatedTime = prevTime - 1; // Уменьшаем время на 1 секунду
              if (updatedTime <= 0) {
                
                clearInterval(intervalId); // Останавливаем интервал, если время истекло
              }
              return updatedTime;
            });
          }, 1000);

        setStartCounting(false)
        setTimeout(() => {                  
          setStartCounting(true)
        }, 300000);
    }
*/


    // Функция для запуска отсчета по клику
    const handleStart = () => {
      setRemainingTime(60)
        setStartCounting(true); // Запускаем отсчет
        setShowingAnimation(true); // Показываем анимацию
        setShowingPers(false);
        setTimeout(() => {            
            setShowingAnimation(false); // Скрываем анимацию после завершения
        }, 1250);
        const newTime = new Date(); // Создаем новый объект времени
        newTime.setMinutes(newTime.getMinutes() + 3); // Прибавляем к минутам три
        setCurrentTime(newTime);

        const intervalId = setInterval(() => {
          setRemainingTime(prevTime => {
            const updatedTime = prevTime - 1; // Уменьшаем время на 1 секунду
            if (updatedTime <= 0) {
              clearInterval(intervalId); // Останавливаем интервал, если время истекло
            }

            return updatedTime;
          });
        }, 1000);
    };


       // Функция для запуска отсчета по клику
       const handleStart_two = () => {
         generateRandomNumber();
         setCounter(0)
          setRemainingTime(60)
          setStartCounting(true); // Запускаем отсчет
          setShowingPers(false);
          const newTime = new Date(); // Создаем новый объект времени
          newTime.setMinutes(newTime.getMinutes() + 3); // Прибавляем к минутам три
          setCurrentTime(newTime);
  
          const intervalId = setInterval(() => {
            setRemainingTime(prevTime => {
              const updatedTime = prevTime - 1; // Уменьшаем время на 1 секунду
              if (updatedTime <= 0) {                
                clearInterval(intervalId); // Останавливаем интервал, если время истекло
              }
              return updatedTime;
            });
          }, 1000);
      };
    /*
    const formatTime = (time) => {
        const hours = time.getHours(); // Получаем часы
        const minutes = time.getMinutes(); // Получаем минуты
        // Форматируем время и добавляем нули перед однозначными значениями
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    };
    */
    useEffect(() => {
        generateRandomNumber(); // Вызываем функцию генерации рандомного числа при монтировании компонента

    },[random]);

    useEffect(() => {
        let intervalId;
        
        if (startCounting) {
          // Интервал для увеличения счетчика каждые 100 миллисекунд
          intervalId = setInterval(() => {
            if (counter < random) { // Увеличиваем до 500, чтобы остановиться на 5.00                
              setCounter(prevCounter => prevCounter + step);
               //setStep(prevStep => prevStep + 0.05); 
               setStep(prevStep => prevStep + 0.4); 
            }
            else{
                setShowingTime(true);
            }
          }, 100);

        } // Каждые 100 миллисекунд (или другое удобное вам значение)
        
        // Очистка интервала при размонтировании компонента
        return () => clearInterval(intervalId);;
      }, [startCounting, counter, step]);

      const gradientStyle = {
        background: 'linear-gradient(to right, #F4D04B, #D46D50)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textDecoration: 'none'
    };


  return (
    <div className="lackyJetBack">    
      <img src={luckyUP}></img>
      <div className='div_kefs'>
      </div>
        

       {showingPers && ( // Показываем анимацию, если showingAnimation равна true
            <img src={pers}></img>
        )}
        
        {showingAnimation && ( // Показываем анимацию, если showingAnimation равна true
                <img src={persfly} alt="Animation" />
        )}
        { startCounting && !showingAnimation && ( // Показываем счетчик, если startCounting равно true и showingAnimation равно false  startCounting &&
               <div>
                <span className='X'> X</span>
                <span className='counter'>{(counter / 100).toFixed(2)}</span>
                </div>  
        )}

        {!startCounting && ( // Показываем сигнал, если startCounting равно false
            <img src={signal} alt="Signal" onClick={handleStart} style={{width:200}}/>
        )}

        {showingTime && remainingTime!=0 && (
            <div className='next_pusk' >
                <div className='timedivstart'>
                  <span className='timestartUp'> {data.timeStartMines}  </span>
                  <span className='timestartDown'> {remainingTime%60} {data.seconds} </span>
                </div> 
            </div>
        )}

        {showingTime &&remainingTime!=0 &&(
          <div class="progress-bar">
            <div class="progress-bar-fill"></div>
          </div>
        )}

        {remainingTime===0 && ( // Показываем сигнал, если startCounting равно false
            <img src={signal} alt="Signal" onClick={handleStart_two} style={{width:200}}/>
        )}
        {/*remainingTime !=60 && remainingTime !=0 && (
            <div className='timedivstart'>
                <span className='timestartUp'> {data.timeStartMines}  </span>
                <span className='timestartDown'> {Math.floor(remainingTime/60)} {data.minutes} {remainingTime%60} {data.seconds} </span>
            </div>

        )*/}

<Link to="/main" className="menu_jet" style={{ textDecoration: 'none' }}>
            <span className='menu_rotate'>↺</span>
            <span style={gradientStyle} > {data.menu} </span>
</Link>
    </div>
  );
}

export default LackyJet;