import { BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./components/Main";
import CoinFlip from "./components/CoinFlip";
import Mines from "./components/Mines";
import { Context } from "./context";
import { useState } from "react";
import Skulls from "./components/Skulls";
import Lacky from './components/LackyJet'

function App() {
  const [lang,setlang]=useState('ru')
  
  return (
    <div style={{ height: '100%' }}>
      <Context.Provider value={{lang,setlang}}>
        <BrowserRouter>
          <Routes>          
            <Route path="/main" element={<Main />} />
            <Route path="/coinflip" element={<CoinFlip />} />            
            <Route path="/mines" element={<Mines />} />                        
            <Route path="/skulls" element={<Skulls />} />   
            <Route path="/lacky" element={<Lacky />} />                      
            <Route path="/" element={<Main  />} /> {/* При точном совпадении пути "/" отображаем компонент Main */}
          </Routes>
        </BrowserRouter>
      </Context.Provider>
    </div>
  );
}

export default App;
