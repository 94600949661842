
import '../styles/Mines_style.css'
import '../images/maines/results/1.png'
import { useContext, useEffect, useRef, useState } from 'react';
import initial from '../images/maines/initial.png'


import res1 from '../images/maines/7/1.mp4'
import res2 from '../images/maines/7/2.mp4'
import res3 from '../images/maines/7/3.mp4'
import res4 from '../images/maines/7/4.mp4'
import res5 from '../images/maines/7/5.mp4'
import res6 from '../images/maines/7/6.mp4'
import res7 from '../images/maines/7/7.mp4'
import res8 from '../images/maines/7/8.mp4'
import res9 from '../images/maines/7/9.mp4'
import res10 from '../images/maines/7/10.mp4'


import res11 from '../images/maines/5/1.mp4'
import res12 from '../images/maines/5/2.mp4'
import res13 from '../images/maines/5/3.mp4'
import res14 from '../images/maines/5/4.mp4'
import res15 from '../images/maines/5/5.mp4'
import res16 from '../images/maines/5/6.mp4'
import res17 from '../images/maines/5/7.mp4'
import res18 from '../images/maines/5/8.mp4'
import res19 from '../images/maines/5/9.mp4'
import res20 from '../images/maines/5/10.mp4'


import res21 from '../images/maines/3/1.mp4'
import res22 from '../images/maines/3/2.mp4'
import res23 from '../images/maines/3/3.mp4'
import res25 from '../images/maines/3/5.mp4'
import res26 from '../images/maines/3/6.mp4'
import res27 from '../images/maines/3/7.mp4'
import res28 from '../images/maines/3/8.mp4'
import res29 from '../images/maines/3/9.mp4'
import res30 from '../images/maines/3/10.mp4'

import res31 from '../images/maines/1/1.mp4'
import res32 from '../images/maines/1/2.mp4'
import res33 from '../images/maines/1/3.mp4'
import res34 from '../images/maines/1/4.mp4'
import res35 from '../images/maines/1/5.mp4'
import res36 from '../images/maines/1/6.mp4'
import res37 from '../images/maines/1/7.mp4'
import res38 from '../images/maines/1/8.mp4'
import res39 from '../images/maines/1/9.mp4'
import res40 from '../images/maines/1/10.mp4'
import { Link } from 'react-router-dom';



function Mines() {
    const [quantityMines, setQuantityMines] = useState(3);
    const [videoSrc, setVideoSrc] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const videoRef = useRef(null);

    // Массив видео
    const videoListMines1 = [
    res31,
    res32,
    res33,
    res34,
    res35,
    res36,
    res37,
    res38,
    res39,
    res40,
    ];

    const videoListMines3 = [
        res21,
        res22,
        res23,
        res25,
        res26,
        res27,
        res28,
        res29,
        res30,
    ];

    const videoListMines5 = [
        res11,
        res12,
        res13,
        res14,
        res15,
        res16,
        res17,
        res18,
        res19,
        res20,
    ];


    const videoListMines7 = [
        res1,
        res2,
        res3,
        res4,
        res5,
        res6,
        res7,
        res8,
        res9,
        res10,
    ];

    // Функция для выбора случайного видео
    const getRandomVideo = () => {
        if(quantityMines===1){
            const randomIndex = Math.floor(Math.random() * videoListMines1.length);
            
            return videoListMines1[randomIndex];
        }
        else if(quantityMines===3){
            const randomIndex = Math.floor(Math.random() * videoListMines3.length);
            
            return videoListMines3[randomIndex];
        }

        else if(quantityMines===5){
            const randomIndex = Math.floor(Math.random() * videoListMines5.length);
            
            return videoListMines5[randomIndex];
        }

        else{
            const randomIndex = Math.floor(Math.random() * videoListMines7.length);
            
            return videoListMines7[randomIndex];
        }
    };

    // Обработчик для запуска видео и остановки на 3-й секунде
    const playVideo = () => {
        setVideoSrc(getRandomVideo()); // Выбрать случайное видео
        console.log(videoSrc)
        setShowVideo(true); // Показать видео
        console.log(quantityMines)
    };

    useEffect(() => {
        const video = videoRef.current;
        if (video && videoSrc) {
            video.currentTime = 0; // Установить начало
            //console.log(video.currentTime +'videj')
            video.play();
            console.log(video.currentTime +'videj')
            const handleTimeUpdate = () => {
                

                if(quantityMines===7&video.currentTime >= 2){  
                                     
                    video.pause();                                     
                }

                if(quantityMines===5&video.currentTime >= 2.7){  
                                     
                        video.pause();                                     
                }

                else if(quantityMines===3&video.currentTime >= 3){
                        video.pause();
                }

                else if(quantityMines===1&video.currentTime >= 5.5){
                    video.pause();
                }
            };
            video.addEventListener('timeupdate', handleTimeUpdate);

            // Очистка эффекта
            return () => {
                video.removeEventListener('timeupdate', handleTimeUpdate);
            };
        }
    }, [videoSrc]);



    const plus = () => {
        if(quantityMines<7)
        setQuantityMines(quantityMines+2)
    }

    const minus = () => {
        if(quantityMines>1)
        setQuantityMines(quantityMines-2)
    }

    return (
        <div className='container'>

        

             {!showVideo && ( // Показывать начальное изображение до начала воспроизведения
                <img src={initial} alt="Initial" className='video' />
            )}
            {showVideo && videoSrc && ( // Показывать выбранное видео
                <video ref={videoRef} src={videoSrc} muted loop className='video' 
                playsInline // Prevents full-screen on iOS
                webkit-playsinline="true" // Ensures playsinline works on older iOS
                style={{ objectFit: "cover" }} // Keeps video within the container
                />
            )}


        <div className='menu_quantity_mines'>
            <div className='div_minus'>
                <span className='span_minus' onClick={minus}>-</span>
            </div>
            <div className='div_quantityMines' >
                <span className='span_quantityMines'>{quantityMines}</span>
            </div>
            <div className='div_plus' onClick={plus}>
                <span className='span_plus'>+</span>
            </div>
        </div>

        <div className='button_signal' onClick={playVideo}>
            <span className='span_button_signal'> Получить сигнал </span>
        </div>

        <Link to="/main" className="menu_mines" style={{ textDecoration: 'none' }}>
            <span className='menu_rotate_mines'>↺</span>
            <span > МЕНЮ </span>
        </Link>
        </div>
       
      );

}
  
export default Mines;