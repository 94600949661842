import { Link } from "react-router-dom";
import '../styles/CoinFlip_style.css'
import React, { useContext, useEffect, useState,Suspense, useRef } from 'react';

import { Canvas, useFrame } from '@react-three/fiber';
import { Environment, OrbitControls, ContactShadows } from '@react-three/drei';
import Coin from '../3D/Coin'
import Model from "../3D/Coin";


export default function CoinFlip() {
  const coinRef = useRef();
    // Состояние для хранения результата (ОРЕЛ или РЕШКА)
    const [result, setResult] = useState(null);

  const [rotationSpeed, setRotationSpeed] = useState(0.1);
  const [flag, setFlag] = useState(false);

  const [showResult, setShowResult] = useState(false);

  const handleRotateClick = () => {
    setShowResult(false); // Скрываем старый результат
    setRotationSpeed(0)
    setRotationSpeed(prevSpeed => Math.min(prevSpeed + 0.5, 5)); // Убедитесь, что скорость не слишком велика
    setFlag(true);


    setTimeout(() => {
      const newResult = Math.random() > 0.5 ? 'ОРЕЛ' : 'РЕШКА';
      setResult(newResult);
      setShowResult(true); // Показываем новый результат после паузы
    }, 1500);


  };

  useEffect(() => {

    let interval;
    if (rotationSpeed > 0 && flag) {

      interval = setInterval(() => {
        //setRotationSpeed(prevSpeed => Math.max(prevSpeed - 0.01, 0)); // Уменьшаем скорость до нуля
      }, 50);

      return () => clearInterval(interval);
    }
  }, [rotationSpeed, flag]);


  return (
    <div className="CoinFlipBack">
      {showResult && (
        <div className="result_flip">
          <span>{result}</span>
        </div>
      )}
      <div className='div_canvas'>
      <Canvas>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <OrbitControls enableZoom={false} />
        <Environment preset="sunset" />
        <Suspense fallback={null}>
          <Coin ref={coinRef} rotationSpeed={rotationSpeed} setRotationSpeed={setRotationSpeed} flag={flag} setFlag={setFlag}/>
        </Suspense>
      </Canvas>
      </div>
      <div onClick={handleRotateClick} className='coin_button_flip'>
        <span className='span_button_flip'>БРОСИТЬ</span>
      </div>

      <Link to="/main" >   
        <div className='coin_button_back'>
          <span className='span_button_flip'>НАЗАД</span>
        </div>       
      </Link>
    </div>
  );
}