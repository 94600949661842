import { useContext, useEffect, useRef, useState } from 'react';
import '../styles/Skulls.css';
import skull from '../images/skulls/skull.png'
import diamond from '../images/skulls/almaz.png'
import { Link } from "react-router-dom";






function Skulls() {
    const [showModal, setShowModal] = useState(false);
    const [animateSkulls, setAnimateSkulls] = useState(false);
    const [raiseSkullIndex, setRaiseSkullIndex] = useState();
    const [showResSpan,setShowResSpan]=useState(false);

    const [hashInput, setHashInput] = useState(''); // Хранение значения input
    const [error, setError] = useState(''); // Хранение сообщения об ошибке


    const handleStartClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    const handleOkClick = () => {

        if (hashInput.length < 120) { 
            return;
        }

        else{
            setShowResSpan(false)
            setShowModal(false);
            setAnimateSkulls(true);
            setTimeout(() => {
                setAnimateSkulls(false);
                // Выбираем случайный череп для поднятия
                setRaiseSkullIndex(Math.floor(Math.random() * 3));
                console.log(raiseSkullIndex)
                setShowResSpan(true)
            }, 5000); // Анимация движения длится 5 секунд
        }
    };

    return (
        <div className="app">
            <div className='div_result'>
            {raiseSkullIndex === 0 && showResSpan&&
                <span className='result_skull'>Левый череп</span>                        
            }

            {raiseSkullIndex === 1 && showResSpan&&
                <span className='result_skull'>Центральный череп</span>                        
            }

            {raiseSkullIndex === 2 && showResSpan&&
                <span className='result_skull'>Правый череп</span>                        
            }       
            </div>

                <div className='div_skulls'>   
                        <div className='div_skull'>
                            <img
                                src={skull} // Замените на фактический URL изображения черепа
                                className={`skull ${animateSkulls ? 'animate_left' : ''} ${raiseSkullIndex === 0 ? 'raise' : ''}`}
                                alt="skull"
                            />    
                            {raiseSkullIndex === 0 &&
                                
                                <img src={diamond} className={ `${animateSkulls?'display_none':'diamond'}`} alt="diamond" />
                                                      
                            }
                        </div>   
                        <div className='div_skull'>
                        <img
                            src={skull} // Замените на фактический URL изображения черепа
                            className={`skull ${animateSkulls ? 'animate_center' : ''} ${raiseSkullIndex === 1 ? 'raise' : ''}`}
                            alt="skull"
                        />    
                            {raiseSkullIndex === 1 && <img src={diamond} className={ `${animateSkulls?'display_none':'diamond'}`} alt="diamond" />}
                        </div>
                        <div className='div_skull'>
                            <img
                                src={skull} // Замените на фактический URL изображения черепа
                                className={`skull ${animateSkulls ? 'animate_right' : ''} ${raiseSkullIndex === 2 ? 'raise' : ''}`}
                                alt="skull"
                            />    
                            {raiseSkullIndex === 2 && <img src={diamond} className={ `${animateSkulls?'display_none':'diamond'}`} alt="diamond" />}
                        </div>                                                             
            </div>

            <div className="button-container">
                 
                <div className="skulls_button_link link" onClick={handleStartClick}>
                    <span className="span_button_link">Начать</span>
                </div>        
                

                <Link to="/main">  
                    <div className="skulls_button_link link">
                        <span className="span_button_link">Назад</span>
                    </div>        
                </Link> 
            </div>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="modal-header">
                            <span className='game_hash_modal_window'>GAME HASH ID</span>
                            <button className="close-button" onClick={handleCloseModal}>×</button>
                        </div>
                        <div className="modal-body">
                            <input type="text" placeholder="Введите hash ID" className='input_modal' onChange={(e) => setHashInput(e.target.value)}/>
                        </div>
                        <div className="modal-footer">
                            <button className="button" onClick={handleOkClick}>OK</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}


export default Skulls;
  