import { Link } from "react-router-dom";
import '../styles/Main.css'
import lacky_svg from '../images/main/lacky.png'
import mines_svg from '../images/main/mines.png'
import skulls_svg from '../images/main/skulls.png'
import coinflip from '../images/main/coin_flip.png'

function Main() {


  return (

    <div className="mainModel">
      <div className="container_link">


        <div className="link">
          <div className="img_link background_image_lacky"></div>
            <span className="span_under_image">Lacky jet</span>
            <Link to="/lacky">  
              <div className="button_link link">
                <span className="span_button_link">Открыть</span>
              </div>        
            </Link>
        </div>


        <div className="link">
          <div className="img_link background_image_mines"></div>
            <span className="span_under_image">Mines</span>
            <Link to="/mines">  
              <div className="button_link link">
                <span className="span_button_link">Открыть</span>
              </div>        
            </Link>
        </div>


        <div className="link">
          <div className="img_link background_image_skulls"></div>
            <span className="span_under_image">Skulls</span>
            <Link to="/skulls">  
              <div className="button_link link">
                <span className="span_button_link">Открыть</span>
              </div>        
            </Link>
        </div>


        <div className="link">
        <div className="img_link background_image_coinflip"></div>
              <span className="span_under_image">CoinFlip</span>
              <Link to="/coinflip">  
                <div className="button_link link">
                  <span className="span_button_link">Открыть</span>
                </div>        
              </Link>
        </div> 
      </div>

    </div>
  );
}

export default Main;